<template>
  <div class="container">
    <div class="card text-center mt-5">
      <div class="card-header bg-warning text-white">
        <h1 class="card-title mb-0">Schweizer Guides</h1>
      </div>
      <div class="card-body">
        <div class="file-upload">
          <input type="file" class="form-control-file mb-3" ref="fileInput" @change="handleFileUpload" accept=".xlsx, .xls" />
          <b-button class="btn btn-success mb-3" @click="downloadZip" :disabled="!isFileUploaded">
            <b-spinner v-if="isProcessing" small type="grow"></b-spinner>
            Download ZIP
          </b-button>
          <div class="progress" v-if="showProgressBar">
            <div class="progress-bar progress-bar-striped bg-success" role="progressbar" :style="{ width: progress + '%' }" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{ progress }}%</div>
          </div>
        </div>
        <div v-if="inconsistentPNs.length > 0" class="alert alert-danger mt-3">
          <strong>Warning!</strong> The following PN numbers have inconsistent email addresses:
          <ul>
            <li v-for="pn in inconsistentPNs" :key="pn">{{ pn }}</li>
          </ul>
        </div>
        <div v-if="logs.length > 0" class="alert alert-info mt-3">
          <strong>Logs:</strong>
          <ul>
            <li v-for="log in logs" :key="log">{{ log }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import * as XLSX from 'xlsx';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { generatePDF } from '../utils/pdfGeneratorswizz';

const jsonData = ref(null);
const isFileUploaded = ref(false);
const showProgressBar = ref(false);
const progress = ref(0);
const fileInput = ref(null);
const isProcessing = ref(false);
const inconsistentPNs = ref([]);
const logs = ref([]); // Reactive array for log messages

const handleFileUpload = (event) => {
  isProcessing.value = true;
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets['Einsätze'];
      if (worksheet) {
        jsonData.value = XLSX.utils.sheet_to_json(worksheet, { raw: false, cellFormula: false }).slice(0, 8000);
        inconsistentPNs.value = checkForInconsistentEmails(jsonData.value);
        isFileUploaded.value = true;
        isProcessing.value = false;
      } else {
        alert("Sheet 'Einsätze' not found!");
        isProcessing.value = false;
      }
    };
    reader.readAsArrayBuffer(file);
  }
};

const checkForInconsistentEmails = (data) => {
  const pnEmails = data.reduce((acc, curr) => {
    if (!acc[curr.PN]) {
      acc[curr.PN] = new Set();
    }
    acc[curr.PN].add(curr.Email);
    return acc;
  }, {});

  return Object.keys(pnEmails).filter(pn => pnEmails[pn].size > 1);
};

const generatePDFsAndZip = async (data) => {
  const zip = new JSZip();

  // Extract unique PN numbers
  const uniquePNs = [...new Set(data.map(item => item.PN))];

  let processedItems = 0;
  const totalItems = uniquePNs.length;

  for (const pn of uniquePNs) {
    const hostData = data.filter(item => item.PN === pn);

    if (hostData.length > 0) {
      const host = {
        Name: hostData[0].Name,
        First: hostData[0].First,
        PN: hostData[0].PN,
        Office: hostData[0].Office,
        Email: hostData[0].Email,
        projects: hostData
      };

      if (host.Email === '0') {
        logs.value.push(`Skipping host: ${host.Name} ${host.First} due to Email being '0'`);
        processedItems++;
        progress.value = Math.round((processedItems / totalItems) * 100);
        continue;
      }

      const pdfData = generatePDF(host);

      if (pdfData) {
        const validDates = host.projects
          .filter(project => parseFloat(project.Delta) !== 0)
          .map(project => project['Date ']);

        const highestMonth = getHighestMonth(validDates);

        const fileName = `${host.Name}_${host.PN}_payroll_${highestMonth}.pdf`;
        zip.file(fileName, pdfData);
      } else {
        logs.value.push(`PDF not generated for: ${host.Name} ${host.First}`);
      }
    } else {
      logs.value.push(`No data found for PN: ${pn}`);
    }

    processedItems++;
    progress.value = Math.round((processedItems / totalItems) * 100);
  }

  return zip.generateAsync({ type: 'blob' });
};

const getHighestMonth = (dateStrings) => {
  const formats = ['DD.MM.YYYY', 'M/D/YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'D/M/YYYY', 'DD/MM/YYYY', 'M/D/YY'];
  let highestMonth = null;

  dateStrings.forEach(dateStr => {
    const date = moment(dateStr, formats, true);
    if (date.isValid()) {
      const monthYear = date.format('MMMM YYYY');
      if (!highestMonth || moment(monthYear, 'MMMM YYYY').isAfter(moment(highestMonth, 'MMMM YYYY'))) {
        highestMonth = monthYear;
      }
    } else {
      console.error(`Invalid date format: ${dateStr}`);
    }
  });

  return highestMonth || 'Invalid Date';
};

const downloadZip = async () => {
  if (jsonData.value) {
    progress.value = 0;
    showProgressBar.value = true;
    isProcessing.value = true;

    const zipContent = await generatePDFsAndZip(jsonData.value);

    const downloadSimulation = setInterval(() => {
      if (progress.value < 100) {
        progress.value += 10;
      } else {
        clearInterval(downloadSimulation);
        saveAs(zipContent, 'Guides.zip');
        showProgressBar.value = false;
        isProcessing.value = false;
        resetFileInput();
      }
    }, 100);
  }
};

const resetFileInput = () => {
  if (fileInput.value) {
    fileInput.value.value = '';
    isFileUploaded.value = false;
  }
};
</script>

<style scoped>
.alert {
  margin-top: 20px;
}
.card-title {
  font-size: 24px;
  font-weight: bold;
}

.container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.card {
  width: 60%;
}
</style>
